<template>
  <div id="about" class="container-xxl">
    <div class="row">
      <div class="col-lg-6 col-md-12 col-sm-12 order-lg-1 order-sm-2">
        <span class="image"></span>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 order-lg-2 order-sm-1">
        <h2 class="text-primary">{{ $t("about.title") }}</h2>
        <div v-html="$t('about.intro')"></div>
        <table class="table">
          <tr v-for="step in steps" :key="step">
            <td>{{ $t("about.cv." + step) }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import Footer from "@/components/Footer.vue";
import steps from "@/locales/de.json";

export default {
  name: "About",
  data() {
    return {
      steps: Object.keys(steps.about.cv),
    };
  },
  components: {
    Footer,
  },
};
</script>

<style scoped>
#about {
  padding-top: 75px;
  padding-bottom: 50px;
}

.image {
  display: block;
  height: 75vh;
  background-color: aliceblue;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("/img/forouzan.jpg");
}

h2 {
  margin-bottom: 30px;
}

table {
  border: 0;
  border-collapse: collapse;
  padding: 0;
  margin: 20px 0 0 0;
  line-height: normal;
}

table td.icon {
  font-size: 36px;
  padding-right: 10px;
  font-weight: 300;
  vertical-align: top;
}
</style>
